import { render, staticRenderFns } from "./FilterPills.vue?vue&type=template&id=318e6d37&scoped=true"
import script from "./FilterPills.vue?vue&type=script&lang=js"
export * from "./FilterPills.vue?vue&type=script&lang=js"
import style0 from "./FilterPills.vue?vue&type=style&index=0&id=318e6d37&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318e6d37",
  null
  
)

export default component.exports