<template>
  <div class="filter-pills-wrapper">
    <div class="fpw__pills" v-for="(p, i) in pillsData" :key="i">
      <p>Filter By</p>
      <span>{{ p.text }}</span>
      <!-- TODO update the clear selection logic -->
      <!-- <img
        src="https://files.lxp.academy.who.int/didactica/assets/images/modal/close.svg"
        alt="close"
        width="14"
        height="14"
        @click="clearSelection(p)"
      /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  computed: {
    pillsData() {
      let arr = [];
      Object.keys(this.filters).forEach(key => {
        // checkbox
        if (Array.isArray(this.filters[key])) {
          this.filters[key].forEach(flk => {
            arr.push(flk);
          });
        }
        // radio
        else if (this.filters[key] !== "") {
          arr.push(this.filters[key]);
        }
      });
      return arr;
    }
  },
  methods: {
    clearSelection(p) {
      this.$emit("clearSelection", p);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-pills-wrapper {
  display: inline-flex;
  margin: 0 0 8px;
  flex-wrap: wrap;
  .fpw__pills {
    background: $brand-neutral-50;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 0 16px 16px 0;
    @include flex-horizontal-center;
    p,
    span {
      @include label-large($brand-neutral-700, 500);
      margin-bottom: 0;
    }
    span {
      color: $brand-neutral-300;
      margin: 0 6px;
    }
    img {
      cursor: pointer;
    }
  }
}
</style>
