<template>
  <div class="upw-filter__menu">
    <div class="upw-filter__menu--div" v-for="(m, i) in menu" :key="i">
      <div class="upw-filter__menu--heading">
        <label class="upw-filter__content">{{ m.heading }}</label>
        <img
          :src="
            m.collapse
              ? require('@/assets/images/partner/user-permissions/chevron-down-grey.svg')
              : require('@/assets/images/partner/user-permissions/chevron-up-grey.svg')
          "
          alt="down"
          class="upw-filter__content"
          @click="toggleCollapse(m)"
        />
      </div>
      <div
        :class="['upw-filter__menu--items', { collapse: m.collapse }]"
        v-if="m.type === 'radio'"
      >
        <LxpRadio
          :options="m.items"
          :name="m.id"
          :id="m.id"
          selectedVal=""
          @input="onInput($event, m)"
          :ref="`filter-${m.id}`"
          class="upw-filter__content"
        />
      </div>
      <div
        :class="['upw-filter__menu--items', { collapse: m.collapse }]"
        v-else-if="m.type === 'checkbox'"
      >
        <LxpCheckbox
          :options="m.items"
          :name="m.id"
          :id="m.id"
          selectedVal=""
          @input="onInput($event, m)"
          :ref="`filter-${m.id}`"
          class="upw-filter__content"
        />
      </div>
    </div>
    <div class="upw-filter__menu--clear" @click="clearFilters()">
      <img src="@/assets/images/partner/shared/delete.svg" alt="clear" />
      <p>Clear</p>
    </div>
  </div>
</template>
<script>
import { LxpRadio, LxpCheckbox } from "didactica";
export default {
  props: {
    filterMenu: {
      type: Array,
      required: true
    }
  },
  components: {
    LxpRadio,
    LxpCheckbox
  },
  data() {
    return {
      menu: []
    };
  },
  created() {
    this.menu = this.filterMenu;
  },
  methods: {
    onInput(e, m) {
      m.selected = e;
      this.emitMenu();
    },
    clearFilters() {
      this.menu.forEach(f => {
        f.selected = f.type === "checkbox" ? [] : "";
        this.$refs[`filter-${f.id}`][0].resetData();
      });
      this.emitMenu();
    },
    emitMenu() {
      this.$emit("update:filterMenu", this.menu);
    },
    toggleCollapse(m) {
      m.collapse = !m.collapse;
    }
  }
};
</script>
<style lang="scss" scoped>
.upw-filter__menu {
  padding: 4px 0px;
  border-radius: 8px;
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  position: absolute;
  z-index: 99;
  width: 165px;
  right: 0;
  top: 55px;
  .upw-filter__menu--heading {
    padding: 8px;
    @include horizontal-space-between;
    label {
      @include label-small;
      font-variant: all-small-caps;
      color: $brand-neutral-300;
    }
    img {
      cursor: pointer;
    }
  }
  .upw-filter__menu--items {
    .upw-filter__content {
      overflow: hidden;
      max-height: 200px;
      transition: max-height 1s ease-in;
    }
    &.collapse {
      .upw-filter__content {
        max-height: 0px;
        transition: max-height 1s ease-out;
      }
    }
  }
  .upw-filter__menu--clear {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    p {
      @include label-large($brand-neutral-300, 500);
      margin: 0 0 0 6px;
    }
  }
}
</style>
<style lang="scss">
.upw-filter__menu--items .lxp-radio .custom-radio {
  padding: 8px;
}
.upw-filter__menu--items .lxp-checkbox .custom-checkbox {
  padding: 8px;
}
</style>
